<script lang="ts">
import Card from "./components/Card.svelte";

</script>

<main>
	<Card/>
</main>

<style lang="scss">
	main {
		width: 100%;
		height: 100%;
		background: #292929;
		display: grid;
		place-items: center;
	}
</style>
