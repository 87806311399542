<script lang="ts">
  import LinkCard from "./LinkCard.svelte";
  import { Link } from "./types/link";
  export let links: Array<Link>;
</script>

<div class="links">
  {#each links as link}
    <LinkCard {link}  />
  {/each}
</div>

<style lang="scss">
  .links {
    display: flex;
	justify-content: space-around;
    padding: 40px;
  }
</style>
