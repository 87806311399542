<script lang="ts">
  import LinkContainer from "./LinkContainer.svelte";
  import { Link } from "./types/link";
  let links: Array<Link> = [
	{
      link: "https://twitter.com/amd64sucks",
      color: "#1DA1F2",
      content: "Twitter",
      rel: null,
    },
	{
      link: "https://wetdry.world/@amd64sucks",
      color: "rgb(41, 67, 205)",
      content: "Mastodon",
      rel: 'me',
    },
	{
      link: "https://en.liberapay.com/amd64sucks/",
      color: "#963312",
      content: "Support my work",
      rel: null,
    },
	{
      link: "https://github.com/jorundev",
      color: "#212121",
      content: "Github",
      rel: null,
    },
  ];
</script>

<div class="bio">
  <div class="title">
	<p>Hello, my name is Jorun.</p>
	<br>
	<p>I&#39;m a french developer, mainly interested by &quot;low level&quot; programming, such as emulation, kernel development, reverse engineering...</p>
	<br>
	<p>I&#39;m still studying right now, so I won&#39;t be doing that much. If you want anything from me here are some links where you can find me</p>
  </div>
  <LinkContainer {links} />
</div>

<style lang="scss">
  .bio {
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    padding: 30px;
    border-radius: 10px;
    background: #353535;
  }
</style>
