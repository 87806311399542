<script lang="ts">
  import { Link } from "./types/link";
  export let link: Link;

  let rootElement;

  $: rootElement && rootElement.style.setProperty('--bcolor', link.color);
</script>

<a bind:this={rootElement} rel={link.rel} href={link.link}>{link.content}</a>

<style lang="scss">
  a {
	text-align: center;
	width: 100%;
	display: block;
    text-decoration: none;
    color: white;
    background-color: var(--bcolor);
    padding: 10px;
  }
</style>
